
import { Vue, Component } from 'vue-property-decorator';
import { addReview } from '@/api/review';

@Component({
})
export default class extends Vue {
  private apiUrl = process.env.VUE_APP_BASE_API;

  private fileSizeLimit = 10;

  private form: any = {
    orderGroupIdx: Number(this.$route.params.idx),
    content: '',
    score: 0,
    photoes: [],
  }

  private goBack() {
    this.$router.push({ name: 'DeliveryDetail', params: { orderGroupId: this.$route.params.idx } });
  }

  private handleBeforeUpload(uploadFile:File, isPhoto:boolean) {
    const fileSizeLimitByMb = this.fileSizeLimit * 1024 * 1024;
    if (isPhoto) {
      const isImageFile = uploadFile.type.split('/')[0] === 'image';
      if (!isImageFile) {
        this.$message.warning('이미지 파일만 업로드 가능합니다.');
        return false;
      }
    }
    if (uploadFile.size > fileSizeLimitByMb) {
      this.$message.warning(`파일 업로드 최대용량은 ${this.fileSizeLimit}MB입니다.`);
      return false;
    }
    return true;
  }

  private handleRemoveImageFile(file: any) {
    const index = this.form.photoes.findIndex((imgFile: any) => imgFile.fileUid === file.fileUid);
    if (index > -1) this.form.photoes.splice(index, 1);
  }

  private handleSuccessUploadImageFile(res: any) {
    this.form.photoes.push({ fileUid: res.uid });
  }

  /* eslint-disable */
  private handleSave() {
    if (this.form.score < 1) {
      this.$message.warning('별점을 선택해주세요.');
      return false;
    }
    if (!this.form.content) {
      this.$message.warning('후기 내용을 입력해주세요.');
      return false;
    }
    if (this.form.photoes.length < 1) {
      this.$message.warning('후기 사진을 1장 이상 등록해주세요.');
      return false;
    }
    addReview(this.form).then(() => {
      this.$message.success('후기 작성이 완료되었습니다.');
      this.$router.go(-1);
    });
  }
}
